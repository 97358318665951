import React from "react";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const navigate = useNavigate();
  return (
    <div className="Container">
      <div className="Content-Container">
        <div className="Content">
          <h1>SMARTWHIPDXB</h1>
          <p>
            The original large cream charger to increase your operation speed
            and precision with the help of an intelligent pressure release tool.
          </p>
        </div>

        <input
          type="button"
          className="navigateBtn"
          onClick={() => navigate("/products")}
          value=" Products"
        />
      </div>
    </div>
  );
}

export default LandingPage;
