import React, { useEffect, useState } from "react";
import image2 from "../assets/CreamDeluxe_yellow/CR_yellow_colors_BG.jpg";
import image3 from "../assets/CreamDeluxe/CD_smoke_BG.jpg";
import image4 from "../assets/smartwhip_silver/Smartwhip-Silver-kitchen-table-top.png";
import { CgArrowLongRight } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { AiOutlineToTop } from "react-icons/ai";
import whatsapp_icon from "../assets/whatsapp_icon.png";
function Products() {
  const [productTitle, setProductTitle] = useState("Smartwhip Silver");
  const [isVisible, setIsVisible] = useState(false);
  const phoneNumber = "+971581864638";
  const message = `Hello, I want to order ${productTitle}`;
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="products-Container">
      <div className="Header">
        <h1>SMARTWHIPDXB</h1>
        <input
          type="button"
          className="navigateBtn"
          onClick={() => navigate("/")}
          value=" Home"
        />
      </div>
      <div className="image-Container" id="imageContainer">
        <div className="card">
          <div className="image1-Container imageDiv">
            <img src={image2} alt="image2" className=" imgProduct" />
            <div className="productDescription">
              <h2>Cream Deluxe Maxxi </h2>
              <p>
                2000 grams of food-grade N2O purity tested at 99,9% wrapped in
                high-grade steel.
              </p>
            </div>
          </div>
          <div className="orderWatsAppProduct">
            <h3>
              <span>Order now</span> <CgArrowLongRight />
            </h3>
            <a
              onClick={() => setProductTitle("Cream Deluxe Maxxi")}
              href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
                message
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsapp_icon} alt="whatsapp_icon" />
            </a>
          </div>
        </div>
        <div className="card">
          <div className="image2-Container imageDiv">
            <img src={image3} alt="image3" className=" imgProduct" />
            <div className="productDescription">
              <h2>Cream Deluxe Gold</h2>
              <p>
                640 grams of food-grade N2O purity tested at 99,9% wrapped in
                high-grade aluminum of strong German craftsmanship
              </p>
            </div>
          </div>
          <div className="orderWatsAppProduct">
            <h3>
              <span>Order now</span> <CgArrowLongRight />
            </h3>

            <a
              onClick={() => setProductTitle("Cream Deluxe Gold")}
              href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
                message
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsapp_icon} alt="whatsapp_icon" />
            </a>
          </div>
        </div>
        <div className="card">
          <div className="image3-Container imageDiv">
            <img src={image4} alt="image4" className=" imgProduct" />
            <div className="productDescription">
              <h2>Smartwhip Silver N2O </h2>
              <p>
                Made entirely from aluminium, Smartwhip Silver is the most
                lightweight nitrous oxide cream charger available in its
                category.
              </p>
            </div>
          </div>
          <div className="orderWatsAppProduct">
            <h3>
              <span>Order now</span> <CgArrowLongRight />
            </h3>
            <a
              onClick={() => setProductTitle("Smartwhip Silver N2O")}
              href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
                message
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsapp_icon} alt="whatsapp_icon" />
            </a>
          </div>
        </div>
      </div>
      <button
        className={`scroll-to-top-button ${isVisible ? "visible" : ""}`}
        onClick={handleClick}
        title="Go to top"
      >
        <AiOutlineToTop size="30px" on />
      </button>
    </div>
  );
}

export default Products;
